import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useGetAuctionFetcher } from '@autobid/ui/composables/useGetAuctionFetcher'

export const getUseAuctionTanstackKey = (id: number, lang: string) => [
  'auction',
  id,
  lang
]

type Params = {
  isRequired?: boolean
  doNotFetch?: boolean // do not pass it if you use a suspense
}

export const useAuction = (id: number | undefined, params?: Params) => {
  const { locale, locales, t } = useI18n()
  const { APP_ID } = useRuntimeConfig().public
  const queryClient = useQueryClient()
  const headers = useRequestHeaders(['cookie'])
  const { fetchAuction } = useGetAuctionFetcher()

  const { data: auction, ...rest } = useQuery(
    getUseAuctionTanstackKey(id, locale.value),
    async () => {
      if (!id) return null

      return await fetchAuction(id, locale.value, locales.value, headers)
    },
    {
      refetchOnWindowFocus: false,
      enabled: !params?.doNotFetch,
      onSuccess: (data) => {
        if ((!data || data.appId !== APP_ID) && params?.isRequired) {
          showError({
            statusMessage: t('error-page.404.description'),
            statusCode: 404
          })
        }
      },
      onError: (error: Error) => {
        showError({
          statusCode: 'statusCode' in error ? error.statusCode : 500,
          statusMessage: error.message
        })
      }
    }
  )

  const prefetch = async () => {
    const queryKey = getUseAuctionTanstackKey(id, locale.value)
    const prefetchedData = queryClient.getQueryData(queryKey)

    if (prefetchedData) return prefetchedData

    const data = await fetchAuction(id, locale.value, locales.value, headers)

    queryClient.setQueryData(queryKey, data)

    return data
  }

  return {
    auction,
    prefetch,
    ...rest
  }
}

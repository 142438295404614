import type { Locale } from '@autobid/ui/types/I18n'

export const getSingleAuctionQueryFields = (
  lang: string,
  locales: Locale[]
) => {
  return `
    id
    type
    department {
      name
      id
    }
    number
    barometerBanner
    startDate
    appId
    restrictions {
      permissionRequired
      permittedCountriesIDs
    }
    stage
    logos {
      url
      alt
    }
    slug {
      default
      ${locales.map((locale) => locale.code.toUpperCase()).join(' ')}
    }
    descriptions {
      title {
        ${lang.toUpperCase()}
      }
      subtitleA {
        ${lang.toUpperCase()}
      }
      subtitleB {
        ${lang.toUpperCase()}
      }
      subtitleC {
        ${lang.toUpperCase()}
      }
    }
    catalog {
      type
      url
    }
    activeStatusesNames
    publicationInfo {
      statusChangeTime
      status
      dataHash {
        items
        auction
      }
    }
    notice {
      ${lang.toUpperCase()} {
        url
        label
      }
    }
    isPaused
    pauseDate
  `
}

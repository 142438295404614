import { convertDataToDisplay } from '@autobid/ui/utils/auctions/convertDataToDisplay'
import type {
  DisplayingAuction,
  SingleAuctionResp
} from '@autobid/ui/types/components/AuctionsData'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import type { Locale } from '@autobid/ui/types/I18n'
import { getSingleAuctionQueryFields } from '../utils/auctions/getSingleAuctionQueryFields'

export const prepareFetchAuction = (
  rawAuctionsData: SingleAuctionResp,
  lang: string
) => {
  if (!rawAuctionsData) return null

  if (rawAuctionsData.errors?.length) {
    throw new Error(rawAuctionsData.errors[0].message)
  }

  if (!rawAuctionsData.data?.singleAuction.item) return null

  const data = [rawAuctionsData.data.singleAuction.item]
  const auction = convertDataToDisplay({
    data,
    lang
  })

  return auction[0]
}

export const getFetchAuctionBody = (
  id: string | number,
  lang: string,
  locales: Locale[]
) => {
  const query = `query singleAuction {
    singleAuction(params: {lang: "${lang}", id: ${id}}) {
      item { ${getSingleAuctionQueryFields(lang, locales)} }
    }
  }`

  return {
    query
  }
}

export const useGetAuctionFetcher = () => {
  const { $customFetch } = useCustomFetch()

  const fetchAuction = async (
    id: string | number,
    lang: string,
    locales: Locale[],
    headers: HeadersInit
  ): Promise<DisplayingAuction | null> => {
    if (typeof id !== 'number' || isNaN(id)) {
      return null
    }

    const rawAuctionsData = await $customFetch<SingleAuctionResp>(
      '/api/backend',
      {
        method: 'POST',
        headers,
        body: {
          ...getFetchAuctionBody(id, lang, locales),
          queryApi: 'auctions',
          queryUrl: '/api/v1/query'
        }
      }
    )

    return prepareFetchAuction(rawAuctionsData, lang)
  }

  return { fetchAuction }
}

import type { BarometerAuction } from '../../types/Car'
import type { Item } from '../../types/components/AuctionsData'

type ConvertDataToDisplayParams = {
  data: Item[] | BarometerAuction[]
  lang: string
}
export const convertDataToDisplay = ({
  data,
  lang
}: ConvertDataToDisplayParams) =>
  (data ?? []).map(({ counters = {}, descriptions, notice, slug, ...rest }) => {
    const _count = counters?.publishedItems ?? 0

    const getSlug = () => {
      const langSlug = slug[lang.toUpperCase()]
      return langSlug || Object.values(slug ?? {})[0]
    }

    return {
      title: descriptions.title?.[lang.toUpperCase()],
      descriptions: [
        descriptions.subtitleA?.[lang.toUpperCase()],
        descriptions.subtitleB?.[lang.toUpperCase()],
        descriptions.subtitleC?.[lang.toUpperCase()]
      ].filter(Boolean),
      notice: Object.values(notice ?? {})[0],
      allSlugs: slug,
      slug: getSlug(),
      count: _count,
      ...rest
    }
  })
